<template>
  <b-container fluid>
    <b-card no-body>
      <b-overlay
        :show="isLoading"
        rounded="sm"
      >
        <div class="m-2">
          <b-row>
            <b-col
              sm="12"
              md="4"
              lg="4"
              xl="4"
            >
              <b-form-group
                label="Loại đề tài"
                label-for="category"
              >
                <v-select
                  id="category"
                  v-model="categoryId"
                  label="name"
                  :options="categories"
                  :reduce="(option) => option.id"
                />
              </b-form-group>
            </b-col>
            <b-col
              sm="12"
              md="4"
              lg="4"
              xl="4"
            >
              <b-form-group
                label="Lĩnh vực"
                label-for="field"
              >
                <v-select
                  id="field"
                  v-model="fieldId"
                  label="name"
                  :options="fields"
                  :reduce="(option) => option.id"
                />
              </b-form-group>
            </b-col>
            <b-col
              sm="12"
              md="4"
              lg="4"
              xl="4"
            >
              <b-form-group
                label="Cấp độ"
                label-for="level"
              >
                <v-select
                  id="level"
                  v-model="levelId"
                  label="name"
                  :options="levels"
                  :reduce="(option) => option.id"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12">
              <div class="w-100 d-flex justify-content-center">
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  class="mr-1"
                  @click="getDataSourcesFromStore"
                >
              <span class="text-nowrap text-right">
                <feather-icon icon="SearchIcon"/> Tìm kiếm
              </span>
                </b-button>
              </div>
            </b-col>
          </b-row>
          <b-row class="mt-2">
            <b-col cols="12">
              <vue-good-table
                :columns="columns"
                :rows="dataSources"
                :pagination-options="paginationOptions"
                :line-numbers="true"
              >
                <div
                  slot="emptystate"
                  style="text-align: center; font-weight: bold"
                >
                  Không có dữ liệu!
                </div>
                <template
                  slot="table-row"
                  slot-scope="props"
                >
              <span v-if="props.column.field === 'action'">
                <b-button
                  v-if="updatable"
                  v-b-modal.approvalResearchSaveModal
                  v-b-tooltip.hover.top="'Phê duyệt'"
                  variant="primary"
                  class="btn-icon"
                  size="sm"
                  @click="currentResearchId = props.row.id"
                >
                  <feather-icon icon="CheckIcon"/>
                </b-button>
              </span>
                  <span v-else>{{ props.formattedRow[props.column.field] }}</span>
                </template>

                <!-- pagination -->
                <template
                  slot="pagination-bottom"
                  slot-scope="props"
                >
                  <div class="d-flex justify-content-between flex-wrap">
                    <div class="d-flex align-items-center mb-0 mt-1">
                      <span class="text-nowrap"> Hiển thị 1 đến </span>
                      <b-form-select
                        v-model="itemsPerPage"
                        :options="itemsPerPageOptions"
                        class="mx-1"
                        @input="(value) => props.perPageChanged({ currentPerPage: value })"
                      />
                      <span class="text-nowrap">của {{ props.total }} bản ghi</span>
                    </div>
                    <div>
                      <b-pagination
                        :value="1"
                        :total-rows="props.total"
                        :per-page="itemsPerPage"
                        class="mt-1 mb-0"
                        @input="(value) => props.pageChanged({ currentPage: value })"
                      />
                    </div>
                  </div>
                </template>
              </vue-good-table>
            </b-col>
          </b-row>
        </div>
      </b-overlay>
    </b-card>

    <ApprovalResearchSave
      :research-id="currentResearchId"
      @succeed="getDataSourcesFromStore"
    />
  </b-container>
</template>

<script>
import {
  BButton,
  BCol, BContainer, BFormGroup, BFormSelect, BOverlay, BPagination, BRow, VBTooltip, BCard
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import {mapActions, mapGetters} from 'vuex'
import {getUser} from '@/auth/utils'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Ripple from 'vue-ripple-directive'
import {hasPermissionForResource, isSystemAdmin} from '@/utils'
import {PermissionCode, ResourceCode} from '@/const/code'
import {VueGoodTable} from 'vue-good-table'
import ApprovalResearchSave from '@/views/research/approval-research/ApprovalResearchSave.vue'

export default {
  name: 'ApprovalResearch',
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  components: {
    ApprovalResearchSave,
    BFormSelect,
    BPagination,
    BButton,
    vSelect,
    BFormGroup,
    BContainer,
    BOverlay,
    BRow,
    BCol,
    VueGoodTable,
    BCard
  },
  data() {
    return {
      user: getUser(),
      isLoading: false,
      categoryId: null,
      fieldId: null,
      levelId: null,
      currentPage: 1,
      itemsPerPage: 20,
      itemsPerPageOptions: [10, 20, 30, 50, 80, 100],
      columns: [
        {
          label: 'Tên đề tài',
          field: 'name',
          sortable: false,
          filterOptions: {
            enabled: true,
            trigger: 'enter',
            placeholder: 'Nhập tên đề tài',
          },
          thClass: 'text-center',
        },
        {
          label: 'Năm',
          field: 'year',
          sortable: false,
          filterOptions: {
            enabled: true,
            trigger: 'enter',
            placeholder: 'Nhập năm',
          },
          thClass: 'text-center',
        },
        {
          label: 'Loại đề tài',
          field: 'category',
          sortable: false,
          filterOptions: {
            enabled: true,
            trigger: 'enter',
            placeholder: 'Nhập loại đề tài',
          },
          thClass: 'text-center',
        },
        {
          label: 'Lĩnh vực',
          field: 'field',
          sortable: false,
          filterOptions: {
            enabled: true,
            trigger: 'enter',
            placeholder: 'Nhập lĩnh vực',
          },
          thClass: 'text-center',
        },
        {
          label: 'Cấp độ',
          field: 'level',
          sortable: false,
          filterOptions: {
            enabled: true,
            trigger: 'enter',
            placeholder: 'Nhập cấp độ',
          },
          thClass: 'text-center',
        },
        {
          label: '',
          field: 'action',
          sortable: false,
          thClass: 'text-center',
          tdClass: 'text-center',
        },
      ],
      currentResearchId: null,
    }
  },
  computed: {
    ...mapGetters({
      categories: 'approvalResearch/categories',
      fields: 'approvalResearch/fields',
      levels: 'approvalResearch/levels',
      dataSources: 'approvalResearch/researches',
    }),
    paginationOptions() {
      return {
        enabled: true,
        perPage: this.itemsPerPage,
      }
    },
    updatable() {
      return isSystemAdmin() || hasPermissionForResource(PermissionCode.UPDATE, ResourceCode.APPROVAL_RESEARCH)
    },
  },
  async created() {
    this.isLoading = true
    try {
      await Promise.all([
        this.getAllCategories(),
        this.getAllFields(),
        this.getAllLevels(),
      ])
      if (this.categories.length > 0) {
        this.categoryId = this.categories[0].id
      }
      if (this.fields.length > 0) {
        this.fieldId = this.fields[0].id
      }
      if (this.levels.length > 0) {
        this.levelId = this.levels[0].id
      }
    } catch (e) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: `[${e.code}] ${e.message}`,
          icon: 'XCircleIcon',
          variant: 'danger',
        },
      })
    } finally {
      this.isLoading = false
    }
  },
  methods: {
    ...mapActions({
      getAllCategories: 'approvalResearch/getAllCategories',
      getAllFields: 'approvalResearch/getAllFields',
      getAllLevels: 'approvalResearch/getAllLevels',
      getResearchesNeedApproved: 'approvalResearch/getResearchesNeedApproved',
      approvedResearch: 'approvalResearch/approvedResearch',
    }),
    async getDataSourcesFromStore() {
      this.isLoading = true
      try {
        await this.getResearchesNeedApproved({
          organizationId: this.user.orgId,
          categoryId: this.categoryId,
          fieldId: this.fieldId,
          levelId: this.levelId,
          employeeId: this.user.employeeId,
        })
      } catch (e) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `[${e.code}] ${e.message}`,
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      } finally {
        this.isLoading = false
      }
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-good-table.scss";
</style>
